import * as React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import logo from "../images/LevellerLogoWhite.svg"
import Head from "../components/head.js"

import "../components/turnstile-refactor.css"


const Home = ({ data }) => {
  const imageData = data.background.childImageSharp.fluid
  
  return (
  <>
    <Head title="JUMP THE TURNSTILE" />

      <BackgroundImage
                  Tag="section"
                  className={"jtt-hero"}
                  fluid={imageData}
                  style={{
                    background: "#c4122ab5 center / cover no-repeat",
                    backgroundBlendMode: "overlay"
                  }}
        >
        <hgroup className="jtt-hero-text">
          <h1>&#8202;jump&#8202;the<br />turnstile</h1>
          <h2><a href="https://crimethinc.com/2019/11/18/friday-november-29-nobody-pays-an-international-call-for-a-strike-against-the-rising-cost-of-living">for free transit <br className="responsive-break" />and freedom itself</a></h2>
        </hgroup>
        <Link to="/"><img id="jump-logo" src={logo} alt="" /></Link>
      </BackgroundImage>
  </>
  )
}


export default Home

export const query = graphql`
  query {
    background: file(relativePath: {eq: "jump-banner.jpg"}) {
      id
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`